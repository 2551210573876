@import '~antd/dist/antd.css';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.isp-header .ant-page-header-heading {
  justify-content: center;
}

.isp-char-table .ant-table-cell {
  padding: 10px 6px;
}

.isp-char-table .ant-table table {
  border-style: solid;
  border-color: black !important;
  border-width: thin;
  background-color: #fff;
}


.isp-abs-table .ant-table-thead tr:nth-child(3) {
  display: none;
}

.isp-char-table .ant-table-thead tr:nth-child(2)>* {
  border-bottom: none;
}

.resultDisplay {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.abstractTable {
  margin-top: 20px;
  margin-bottom: 10px;
  flex-direction: column;
}

.referenceBlock {
  display: flex;
  flex-direction: column;
  align-Items: center;
}

.referenceBlock-vertical {
  display: flex;
  flex-direction: column;
  align-Items: center;
  padding-left: 42px;
  padding-right: 42px;
  padding-bottom: 20px;
}

.referenceBlock-horizontal {
  display: flex;
  flex-direction: column;
  align-Items: center;
  padding-right: 40px;
  border-right: solid;
  border-width: thin;
}

.result-vertical {
  padding-top: 20px;
  border-top: solid;
  border-width: thin;
}

.result-horizontal {
  padding-left: 40px;
}

div.navigation > *:hover{
  color: #b7e4d5 !important
}